import { MainLayout } from "../../../layout";

export const TermsAndConditionPage = () => {
  return (
    <MainLayout>
      <div className="py-20 container mx-auto xl:lg:md:px-0 px-5">
        <h6 className="underline text-2xl text-center text-primary-500">
          TERMS OF USE
        </h6>
        <p className="text-center my-3">
          Welcome to <b className="text-primary-500">AlterBuddy</b> (the
          “Organization”, “we”, “us”, “our”).
        </p>
        <p className="text-gray-500 whitespace-pre-wrap">
          Kindly peruse the following Terms of Use (“Agreement”) carefully before proceeding to avail/use the
          services of ‘www.alterbuddy.org’ (the “Site”). This Agreement sets forth the legally binding terms
          and conditions for your use and access to the Site and the related services or content offered via the
          said Site as well as our Social Media Channels (collectively, the “Services”). By accessing to and using
          the Services, you confirm that you are at least 18 years of age & above, and you agree to be legally
          bound by this Agreement.
          {"\n\n"}
          By accessing to and using the Services, you confirm that you are at
          least 18 years of age & above, and you agree to be legally bound by
          this Agreement.
        </p>
        <h6 className="text-xl text-primary-500 underline my-5 font-semibold">
          DISCLAIMER
        </h6>
        <p className="text-gray-500 whitespace-pre-wrap">
          The Services provide an informal guidance/counselling and should not be treated as substitute for
          professional medical advice. We do not guarantee any health-related outcomes after using our
          Services. Always seek professional medical advice for any health-related issues. Use of the Services is
          at your own risk. With respect to our Manifestation and Healing services, we do not guarantee any
          100% results, as the same solely depends upon your discipline, patience, letting go, consistency and
          various other factors. We gather your personal information to enhance our customer service by
          providing you with easy access to our products and services, focusing on what interests you the
          most. Your information also helps us keep you informed about new product launches, software
          updates, special offers, and events that may interest you. You have the option to “opt in” or “opt
          out” of certain uses of your personal information. For instance, when you provide personal
          information on our websites, you can choose whether to receive correspondence from us. You can
          opt out of marketing emails or SMS by contacting us and requesting removal from specific marketing
          lists or all communications. However, you cannot opt out of essential communications related to
          your account transactions. AlterBuddy values your privacy and does not sell or rent your contact
          information to other marketers. Unless otherwise disclosed, we will not share your personal
          information with third parties without notifying you first and giving you the chance to prevent such
          sharing. Healing, spiritual readings, or energy work should not be considered substitutes for medical
          or psychological advice or treatment. If you or someone you know has a health issue, please seek
          treatment from a registered medical professional. Healing is a natural method for energy balance,
          stress reduction, and relaxation, but any reliance on such healing is entirely at your own risk. We are
          not liable for any indirect or consequential loss or damage. All information and communication with
          AlterBuddy are intended for educational, spiritual, and entertainment purposes only. Psychic
          readings and services from AlterBuddy are for entertainment and should not be relied upon in place
          of professional services, including medical, legal, financial, business, relationship, or psychological
          advice. AlterBuddy accepts no liability or responsibility for any actions or decisions made by clients
          based on their consultations. By using AlterBuddy's services, you agree to the terms of service and
          disclaimer. Content used on the website, videos, and blogs is for educational and informational
          purposes only. All rights to images, music, clips, and other materials belong to their respective
          owners, and no claim of ownership is made over third-party content. Testimonials from clients are
          genuine and shared with their consent on the website, social media channels, and videos to enhance
          educational and informational purposes. The information provided in training is for informational,
          inspirational, and educational purposes only. We are not liable for any loss incurred while using our
          services. There is no guarantee that you will earn any money using the techniques and ideas on our
          website, application, phone, or handbook; results depend on the individual. All readings are for
          guidance or entertainment purposes only and are intended for individuals aged 18 and older. Any
          meditation, meditation videos, affirmations, or pre-prepared content are used at your own risk. We
          accept no legal liability or responsibility for any outcomes arising from the use of these materials.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          PRIVACY NOTICE:
        </h6>
        <p className="text-gray-500">
          Your use of the Services is subject to our Privacy Notice, which explains our practices regarding the
          collection and processing of your information. The acceptance of our Privacy Notice by you, after
          thoroughly reading the terms and conditions therein, is a condition precedent for the use of our
          Services.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          RULES OF CONDUCT:
        </h6>
        <ul className="list-disc list-inside space-y-2 text-gray-500">
          <li>To comply with all applicable laws and regulations.</li>
          <li>To respect the rights and dignity of other users.</li>
          <li>
            To be solely responsible for all equipment and charges, as necessary for accessing the
            Services.
          </li>
          <li>
            Prohibited actions include, but are not limited to the following:
          </li>
          <ul className="list-disc list-inside ml-6 space-y-2">
            <li>Using the Services for any unlawful or illegal purposes.</li>
            <li>Harassing or threatening other users.</li>
            <li>Interfering with the operation of the Services.</li>
            <li>Unauthorized advertising or commercial exploitation.</li>
            <li>
              Sharing personal information outside the Site with the Counsellors (Buddies),
              Manifestation Coach (Genies), or Alternate Therapy Coach (Healers) and all/any other
              mentors or experts available on the platform.
            </li>
          </ul>
        </ul>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          REGISTRATION:
        </h6>
        <p className="text-gray-500">
          You are required to make sure that you are of 18 years and above age and register on the Site, to get
          access to certain Services. Kindly keep your username and password confidential. You are responsible
          for all the activities that occur/happen with, in or under your account.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          USER GENERATED CONTENT:
        </h6>
        <p className="text-gray-500">
          By submitting your content to the Services, you grant us a perpetual, worldwide, royalty-free license
          to use, modify and distribute your content. You represent that you own or have the rights to the
          content you submit.
        </p>
        <p className="text-gray-500 underline font-semibold my-3">
          Prohibited content includes the following:
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-500">
          <li>Unlawful, illegal, or harmful material.</li>
          <li>Viruses or harmful code.</li>
          <li>Unauthorized advertising.</li>
          <li>
            We reserve the right to monitor, modify, and/or remove the user
            content at our discretion.
          </li>
        </ul>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          INTELLECTUAL PROPERTY RIGHTS:
        </h6>
        <p className="text-gray-500">
          The Services and all the content, excluding user content, are protected by copyrights, trademark, and
          other applicable laws. You should not use or reproduce any of our Proprietary Material, without prior
          written/digital permission from us.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          THIRD PARTY LINKS:
        </h6>
        <p className="text-gray-500">
          The Services may contain links that direct to the third-party websites. We are not responsible for the
          content or practices of such third-party websites. Users are advised to access the same at their own
          risk and discretion
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          INDEMNIFICATION
        </h6>
        <p className="text-gray-500">
          You hereby agree to indemnify and hold the Organization and its affiliates, harmless from any claims,
          disputes, etc. arising because of your use of the Services or because of violation of this Agreement.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          LIMITATION OF LIABILITY:
        </h6>
        <p className="text-gray-500">
          To the extent permitted by law, we are not liable for any damages or penalties arising from your use
          of the Services. Your sole remedy will be to discontinue the usage of the Services.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          ARBITRATION:
        </h6>
        <p className="text-gray-500">
          In the event of any dispute or difference arising out of or in connection with this Agreement, the
          same shall be referred to and resolved through arbitration. The said arbitration proceedings shall be
          conducted in accordance with the provisions of the Arbitration and Conciliation Act, 1996 or any
          statutory re-enactment or modification thereof for the time being in force. The place and venue of
          arbitration shall be Mumbai. The arbitration proceedings shall be conducted in the English language.
          The Decision of the aforesaid Arbitrator shall be final, conclusive, and binding upon both you and the
          Organization.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          TERMINATION:
        </h6>
        <p className="text-gray-500">
          We may terminate your access to the Services, at any given point of time, for any reason whatsoever.
          Upon the termination, your right to use the Services will be ceased immediately.

        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          GOVERNING LAW & JURISDICTION:
        </h6>
        <p className="text-gray-500">
          This Agreement along with its meaning and interpretation shall be governed by and construed in
          accordance with the applicable laws of India and the Courts of Mumbai alone shall have jurisdiction
          for all disputes and matters arising with respect to this Agreement. The Services are controlled and
          operated from India. If you access the Services from anywhere outside India, you shall do the same
          at your own risk and you shall be responsible for the compliance with the local laws.
        </p>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          MISCELLANEOUS:
        </h6>
        <ul className="list-disc list-inside space-y-2 text-gray-500">
          <li>
            This Agreement constitutes the entire agreement between you and the Organization
            regarding the Services. We may modify this Agreement at any time. Your continued use of
            the Services indicates the uninterrupted acceptance of such updated and modified terms.

          </li>
          <li>
            All sessions conducted by our consultant with you through calls, videos, chats, etc., will be
            extracted in audio form. Additionally, the chat history will also be maintained. Both the audio
            recordings and transcripts will be always retained and made available on our website for
            records, training, quality, and safety purposes.
          </li>
        </ul>
        <h6 className="text-xl text-primary-500 underline py-5 font-semibold">
          CANCELLATION & RETURN POLICY:
        </h6>
        <ul className="text-gray-500 list-disc list-inside space-y-2">
            <p>
              AlterBuddy values your privacy and does not sell or rent your contact information to other
              marketers. Unless otherwise disclosed or required by law (such as after consulting with legal
              counsel), we will not share your personal information with third parties without notifying you first
              and giving you the chance to prevent such sharing.
            </p>
            
            <li>
              If the Consultancy Service is cancelled by the Client on or before 7 days of the appointment
              date, the amount will be refunded after deducting payment gateway and website charges.
            </li>
            <li>
              The amount will not be refunded if the Consultancy Service is cancelled by the Client within a
              duration of less than 7 days before the appointment date.
            </li>
            <li>
              No amount will be refunded in case the Client does not show up for the session at the
              scheduled appointment time.
            </li>
            <li>
              If any kind of session is ended by the client on his will before the designated time or the time
              chosen by them, no refund will be granted.
            </li>
            <li>
              No amount will be refunded if any working/ritual/course/energy work/spells is cancelled by
              the Client or if only the partial payment is made.
            </li>
            <li>
              100% of the amount will be refunded if a product order is cancelled by the Client within 24
              hours of placing the order.
            </li>
            <li>
              Refund will be granted after 14 working days.
            </li>
            <li>
              50% of the amount will be refunded if a product order is cancelled by the Client after 24
              hours of placing the order and before the order is shipped.
            </li>
            <li>
              No amount will be refunded if a product order is cancelled by the Client after the order has
              already been shipped.
            </li>
            <li>
              Any Returns and Exchanges will not be entertained.
            </li>
            <li>
              Payment gateway charges and website charges will be deducted from refunds.
            </li>
            <li>
              Rant feature sessions have a time limit. If a session funded by the Client exceeds the due
              time limit, the amount will not be refunded.
            </li>
            <li>
              If a therapist or consultant discontinues a Rant session for an inappropriate or illegal reason
              (Client behaviours), the amount will not be refunded to the Client.
            </li>
        </ul>
      </div>
    </MainLayout>
  );
};
